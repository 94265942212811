import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CRM_PORT}${process.env.VUE_APP_API_PATH}/crm/checkout`;
const urlBase = `${api}/demandasexpress`;

export default {
    obterTodas(filtro) {
        return axiosJwt.get(
            `${urlBase}?dataRegistroDe=${filtro.dataRegistroDe}&dataRegistroAte=${filtro.dataRegistroAte}`
        );
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },
};
