<template>
    <painel titulo="CRM - Checkout - Demandas Express" icone="pi pi-comments" :refreshFunction="atualizar" v-show="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Registro (DE)</label>
                    <Calendar v-model="dataRegistroDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Registro (ATÉ)</label>
                    <Calendar v-model="dataRegistroAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
            </div>
        </Panel>
        <msg-box :itens="mensagens"></msg-box>
        <tabela
            :data="demandasExpress"
            dataKey="demandaExpressId"
            :globalFilterFields="['nomeProjeto', 'statusDescr', 'integracaoRetorno']"
            stateKey="dt-crm-checkout-demandasexpress"
        >
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <div class="white-space-nowrap">
                            <btn-detalhar :demandaExpress="slotProps.data"></btn-detalhar>
                        </div>
                    </template>
                </Column>
                <Column field="dataRegistro" header="Recebido em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataRegistro, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="nomeProjeto" header="Projeto" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nomeProjeto }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true" filterField="status" :showFilterMatchModes="false">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
                <Column field="integracaoRetorno" header="Retorno Integração" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.integracaoRetorno }}
                    </template>
                </Column>
                <Column field="dataCadastro" header="Atualizado em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import DemandasExpressServices from './services';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            demandasExpress: null,
            dataRegistroAte: null,
            dataRegistroDe: null,
            carregado: false,
            filtros: null,
            demandasExpressFiltradas: [],
            mensagens: [],
        };
    },

    methods: {
        obterDemandasExpress() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataRegistroDe: this.$moment(this.dataRegistroDe).format('YYYY-MM-DD'),
                dataRegistroAte: this.$moment(this.dataRegistroAte).format('YYYY-MM-DD'),
            };
            DemandasExpressServices.obterTodas(filtroDto).then((response) => {
                if (response?.success) {
                    this.demandasExpress = response.data;
                } else {
                    this.demandasExpress = null;
                }
                if (!this.carregado) {
                    this.carregado = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterDemandasExpress();
        },

        dadosFiltrados(filterData) {
            this.atendimentosFiltrados = [...filterData.filteredValue];
        },
    },

    created() {
        this.dataRegistroAte = new Date();
        this.dataRegistroDe = new Date(this.$moment(this.dataRegistroAte).subtract(1, 'months').format('YYYY-MM-DD'));
        this.obterDemandasExpress();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'CRM_Checkout_DemandasExpress';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },
    },

    watch: {
        dataRegistroDe() {
            if (this.carregado) {
                this.obterDemandasExpress();
            }
        },

        dataRegistroAte() {
            if (this.carregado) {
                this.obterDemandasExpress();
            }
        },
    },
};
</script>

<style>
.filtro .p-panel-header {
    background-color: rgb(212, 246, 255) !important;
}
</style>
